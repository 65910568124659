import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../../../Contexts/AuthContext/AuthProvider';
import axios from 'axios';
import CustomPagination from '../../../../../CustomPagination/CustomPagination';
import SingleItem from './SingleItem';

const LoanHistory = () => {
    const { authUser } = useContext(AuthContext);
  const [loanHistory, setLoanHistory] = useState([]);
  const [loanHistoryPaginateData, setLoanHistoryPaginateData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (authUser?._id) {
      fetch(
        `${process.env.REACT_APP_API}/api/user/loan/history/view/${authUser?._id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setLoanHistory(data.data);
          setLoanHistoryPaginateData(data);
          setLoading(false);
        });
    }
  }, [authUser?._id]);

  const handleloanHistoryPage = (event) => {
    try {
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .get(
          `${process.env.REACT_APP_API}/api/user/loan/history/view/${authUser?._id}?page=${pageNumber}`,
          config
        )
        .then((response) => {
          setLoanHistory(response?.data?.data);
          setLoanHistoryPaginateData(response?.data);
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
  };
    return (
        <>
            {/* History  */}
          <div className="user-data-table">
            <div className="col-xxl-12 col-md-12 pt-5">
              <div className=" text-white card ">
                <div className="card-header d-flex flex-wrap justify-content-between">
                  <h4 className="title text-primary">My Loan</h4>
                </div>
                <div className="card-body  text-white p-2">
                  <div className="text-white table-responsive ">
                    <table
                      className="text-white table table-responsive-md "
                    >
                      <thead>
                        <tr
                          className=" mx-auto table-header"
                        >
                          <th>S.N.</th>
                          <th>Loan&nbsp;Plan</th>
                          <th>Amount</th>
                          <th>Installment&nbsp;Amount</th>
                          <th>Installment</th>
                          <th>Next&nbsp;Installment</th>
                          <th>Paid</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loanHistory?.length !== 0 ? (
                          loanHistory.map((data, index) => {
                            if (data) {
                              return (
                                <SingleItem
                                  data={data}
                                  index={index}
                                  key={data?._id}
                                  paginateData={loanHistoryPaginateData}
                                ></SingleItem>
                              );
                            }
                          })
                        ) : (
                          <tr>
                            <td
                              className="text-muted text-center"
                              colSpan="100%"
                            >
                              No records available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {/* paginateLinks */}
                  <CustomPagination
                    data={loanHistoryPaginateData}
                    handlePage={handleloanHistoryPage}
                  ></CustomPagination>
                  {/* paginateLinks */}
                </div>
              </div>
            </div>
          </div>
        </>
    );
};

export default LoanHistory;