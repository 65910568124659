import React, { useContext, useEffect, useRef, useState } from "react";
import "./Loan.css";
import SingleItem from "./SingleItem";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../../Contexts/AuthContext/AuthProvider";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import LoanHistory from "./LoanHistory/LoanHistory";
const Loan = () => {
  const { authUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/api/user/loan/package/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data?.data);
        setLoading(false);
      });
  }, []);

  // Handle submit form
  const [updateData, setUpdateData] = useState([]);
  const refSubmitDis = useRef();
  const [errorMessage, setErrorMessage] = useState([]);

  const navigate = useNavigate();

  const handleSubmitData = async (event) => {
    event.preventDefault();
    refSubmitDis.current.setAttribute("disabled", true);
    const formData = new FormData(event.currentTarget);
    const InpuData = Object.fromEntries(formData);
    setErrorMessage();
    try {
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };

      await axios
        .post(
          `${process.env.REACT_APP_API}/api/user/loan/amount/check`,
          { user_id: authUser?._id, ...InpuData },
          config
        )
        .then((data) => {
          setUpdateData(data?.data);
          const packageId = data?.data?.data?._id;
          if (packageId) {
            const Location = `/loan/confirm/${data?.data?.amount}/${packageId}`;
            navigate(Location, { replace: true });
            refSubmitDis.current.removeAttribute("disabled");
            event.target.reset();
          }
        })
        .catch((error) => {
          setErrorMessage({ id: InpuData?.id, ...error?.response?.data });
          refSubmitDis.current.removeAttribute("disabled");
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <section className="user-loan-area">
        <div className="container">
          <div className="user-form-back">
            <Link to="/user/menu">
              {" "}
              <FontAwesomeIcon icon={faChevronLeft} className="text-start" />
            </Link>
            <h6>Loan</h6>
          </div>
          <div className="row">
            {data?.length !== 0 ? (
              data.map((data, index) => {
                if (data) {
                  return (
                    <SingleItem
                      data={data}
                      index={index}
                      key={data._id}
                      handleSubmitData={handleSubmitData}
                      refSubmitDis={refSubmitDis}
                      errorMessage={errorMessage}
                    ></SingleItem>
                  );
                }
              })
            ) : (
              <>
                <div className="text-center h-50">
                  <h6> Data not found</h6>
                </div>
              </>
            )}
          </div>

          <LoanHistory />
        </div>
      </section>
    </>
  );
};

export default Loan;
