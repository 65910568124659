import React from "react";
import FinancialExchange from "./FinancialExchange/FinancialExchange";
import BuiltOn from "./BuiltOn/BuiltOn";
import TradeApp from "./TradeApp/TradeApp";
import OurMission from "./OurMission/OurMission";
import TradingPlatfrom from "./TradingPlatfrom/TradingPlatfrom";
import EarningToday from "./EarningToday/EarningToday";
import MoreControl from "./MoreControl/MoreControl";
import Customers from "./Customers/Customers";

const Home = () => {
  return (
    <>
      <FinancialExchange />
      <BuiltOn />
      <TradeApp />
      <OurMission />
      <MoreControl />
      <Customers />
      <TradingPlatfrom />
      <EarningToday />
    </>
  );
};

export default Home;
