import React from "react";
import "./TradingPlatfrom.css";

const TradingPlatfrom = () => {
  return (
    <>
      <section className="trading-platfrom-section">
      <div className="container">
        <div className="ttl centered"  data-aos="fade-down"  data-aos-duration="2000">
        <span>Why do more users choose Rubicon Liberty Group <br /> Trading Platfrom?</span>
        </div>
        <div className="adv-items flex">
          <div className="adv-item" data-aos="zoom-in-up" data-aos-duration="2000">
            <div className="icon"></div>
            <div className="txt">
              Keep track of charts and prices of coins and tokens
            </div>
          </div>
          <div className="adv-item" data-aos="zoom-in-up" data-aos-duration="2000">
            <div className="icon"></div>
            <div className="txt">
              Store secure coins to minimize the risks of market volatility
            </div>
          </div>
          <div className="adv-item " data-aos="zoom-in-up" data-aos-duration="2000">
            <div className="icon"></div>
            <div className="txt">You earn your profit with additional digital currency with stacking.</div>
          </div>
          <div className="adv-item" data-aos="zoom-in-up" data-aos-duration="2000">
            <div className="icon"></div>
            <div className="txt">Securely store coins and protect your privacy</div>
          </div>
          <div className="adv-item" data-aos="zoom-in-up" data-aos-duration="2000">
            <div className="icon"></div>
            <div className="txt">
            Expand your portfolio with your digital currency with a credit or debit card.
            </div>
          </div>
          <div className="adv-item" data-aos="zoom-in-up" data-aos-duration="2000">
            <div className="icon"></div>
            <div className="txt">
              Make instant exchanges and trade assets in one app.
            </div>
          </div>
        </div>
      </div>
      </section>
    </>
  );
};

export default TradingPlatfrom;
