import React from "react";
import dateFormat from "dateformat";
import Countdown from "react-countdown";
const SingleItem = ({ data }) => {
  const c_profit = parseFloat(data?.profit_amount) - parseFloat(data?.amount);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span className="badge bg-primary">completed</span>;
    } else {
      return (
        <span>
           {days}d&nbsp;{hours}h&nbsp;{minutes}m&nbsp;{seconds}s
        </span>
      );
    }
  };

  return (
    <>
      <tr>
        <td>
          <div class="multiline">
            <span class="date d-block">
              {dateFormat(data?.createdAt, "dd")}-{dateFormat(data?.createdAt, "mm")}-{dateFormat(data?.createdAt, "yyyy")}
            </span>
            <span class="time d-block">
              {dateFormat(data?.createdAt, " h:MM TT")}
            </span>
          </div>
        </td>
        <td>{data?.amount}$</td>
        <td>{data?.profit_amount}$</td>
        <td>{c_profit}$</td>

        <td>
          <div class="multiline">
            <span
              class="remaining-time remainingTime"
              data-time_remaining="240325"
            >
              <Countdown date={data?.expired_time} renderer={renderer} />
            </span>
          </div>
        </td>
        <td>
          <div class="multiline">
            <span class="date d-block">
            {dateFormat(data?.expired_time, "dd")}-{dateFormat(data?.expired_time, "mm")}-{dateFormat(data?.expired_time, "yyyy")}
            </span>
            <span class="time d-block">
              {dateFormat(data?.expired_time, "h:MM TT")}
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

export default SingleItem;
