import React from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons";

const SingleItem = ({ data, livePrice, index }) => {
    return (
        <>
             <div className="markets-item">
              <div className="d-flex justify-content-between align-items-center">
                <div className="markets-item-name">
                  <LazyLoadImage
                    alt=" img"
                    src={`${process.env.REACT_APP_API}/${data?.image}`}
                  />
                  <h6> {data?.Symbol}
                  {data?.currency}</h6>
                </div>
                <div className="markets-value">
                  <p>{livePrice[index]?.ask}</p>
                  {/* <span>
                    +0.03% <FontAwesomeIcon icon={faCaretUp} />
                  </span> */}
                </div>
              </div>
            </div>
        </>
    );
};

export default SingleItem;