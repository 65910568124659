import React from "react";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";
const GridSingleItem = ({ data }) => {
  return (
    <>
      <div className="col-12">
        <Link to={`/user/news/details/${data?._id}`}>
          <div className="hover">
            <div className="module">
              <div className="thumbnail">
                <img
                  src={`${process.env.REACT_APP_API}/${data?.image}`}
                  alt=""
                />
                <div className="date">
                  <div>{dateFormat(data?.createdAt, "dd")}</div>
                  <div>{dateFormat(data?.createdAt, "mmm")}</div>
                </div>
              </div>
              <div className="content">
                <h1 className="title">{(data?.title).slice(0, 70)}{(data?.title).length > 69 && "..."}</h1>
                <p className="description">{data?.dis}</p>
                <span>Read More</span>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default GridSingleItem;
