import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Aos from "aos";
import "aos/dist/aos.css"

import MainMaster from "./Components/Layouts/MainMaster";
import Home from "./Components/Home/Home";
import LoginForm from "./Components/Auth/LoginForm/LoginForm";
import SignUpForm from "./Components/Auth/SignUpForm/SignUpForm";
import About from "./Components/Pages/About/About";
import ContactUs from "./Components/Pages/ContactUs/ContactUs";
import Benefits from "./Components/Pages/Benefits/Benefits";
import Forex from "./Components/Pages/Forex/Forex";
import Mission from "./Components/Pages/Mission/Mission";
import Security from "./Components/Pages/Security/Security";
import Jobs from "./Components/Pages/Jobs/Jobs";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import PrivacyPolicy from "./Components/Pages/PrivacyPolicy/PrivacyPolicy";
import RiskDisclosure from "./Components/Pages/RiskDisclosure/RiskDisclosure";
import UserPrivateRoute from "./Route/UserPrivateRoute";
import MainDashboard from "./Components/Pages/User/Layouts/MainDashboard/MainDashboard";
import LoginRoute from "./Route/LoginRoute";
import Market from "./Components/Pages/User/Pages/Market/Market";
import Orders from "./Components/Pages/User/Pages/Orders/Orders";
import TradePage from "./Components/Pages/User/Pages/TradePage/TradePage";
import UserMenu from "./Components/Pages/User/Pages/UserMenu/UserMenu";
import Deposit from "./Components/Pages/User/Pages/Deposit/Deposit";
import KYC from "./Components/Pages/User/Pages/KYC/KYC";
import Withdrawal from "./Components/Pages/User/Pages/Withdrawal/Withdrawal";
import WithdrawalPrivateRoute from "./Route/WithdrawalPrivateRoute";
import UpdatePassword from "./Components/Pages/User/Pages/UpdatePassword/UpdatePassword";
import UpdateProfile from "./Components/Pages/User/Pages/UpdateProfile/UpdateProfile";
import BitcoinMining from "./Components/Pages/User/Pages/Mining/BitcoinMining/BitcoinMining";
import GoldMining from "./Components/Pages/User/Pages/Mining/GoldMining/GoldMining";
import Loan from "./Components/Pages/User/Pages/Loan/Loan";
import LoanConfirmPage from "./Components/Pages/User/Pages/Loan/LoanConfirmPage/LoanConfirmPage";
import InvestmentPlan from "./Components/Pages/User/Pages/InvestmentPlan/InvestmentPlan";
import NewsPage from "./Components/Pages/NewsPage/NewsPage";
import NewsDetails from "./Components/Pages/NewsPage/NewsDetails";

function App() {
  useEffect(()=>{
    Aos.init({duration:1000});
    },[])
  return (
    <>
     <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />

    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<MainMaster />}>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<LoginRoute > <LoginForm /> </LoginRoute>} />
          <Route path="/signup" element={<LoginRoute > <SignUpForm /> </LoginRoute>} />
          <Route path="/about/us" element={<About />} />
          <Route path="/contact/us" element={<ContactUs />} />
          <Route path="/benefits" element={<Benefits />} />
          <Route path="/forex" element={<Forex />} />
          <Route path="/mission" element={<Mission />} />
          <Route path="/security" element={<Security />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/privacy/policy" element={<PrivacyPolicy />} />
          <Route path="/risk/disclosure" element={<RiskDisclosure />} />
        </Route>
        <Route path="/user/" element={<MainDashboard />}>
          <Route path="markets" element={<UserPrivateRoute > <Market /> </UserPrivateRoute>} />
          <Route path="order" element={<UserPrivateRoute > <Orders /> </UserPrivateRoute>} />
          <Route path="trade" element={<UserPrivateRoute > <TradePage /> </UserPrivateRoute>} />
          <Route path="menu" element={<UserPrivateRoute > <UserMenu /> </UserPrivateRoute>} />
          <Route path="news" element={<UserPrivateRoute > <NewsPage /> </UserPrivateRoute>} />
          <Route path="news/details/:id" element={<UserPrivateRoute > <NewsDetails /> </UserPrivateRoute>} />
        </Route>
        <Route path="/assets/deposit" element={<UserPrivateRoute > <Deposit /> </UserPrivateRoute>} />
        <Route path="/id-auth/kyc" element={<UserPrivateRoute > <KYC /> </UserPrivateRoute>} />
        <Route path="/assets/withdraw" element={<UserPrivateRoute > <WithdrawalPrivateRoute > <Withdrawal />  </WithdrawalPrivateRoute> </UserPrivateRoute>} />
        <Route path="/password/update" element={<UserPrivateRoute > <UpdatePassword /> </UserPrivateRoute>} />
        <Route path="/profile/update" element={<UserPrivateRoute > <UpdateProfile /> </UserPrivateRoute>} />
        <Route path="/bitcoin/mining" element={<UserPrivateRoute > <BitcoinMining /> </UserPrivateRoute>} />
        <Route path="/gold/mining" element={<UserPrivateRoute > <GoldMining /> </UserPrivateRoute>} />
        <Route path="/apply/loan" element={<UserPrivateRoute > <Loan /> </UserPrivateRoute>} />
        <Route path="/loan/confirm/:amount/:id" element={<UserPrivateRoute > <LoanConfirmPage /> </UserPrivateRoute>} />
        <Route path="/investment/plan" element={<UserPrivateRoute > <InvestmentPlan /> </UserPrivateRoute>} />
       
      </Routes>
    </BrowserRouter>
    </>
   
  );
}

export default App;
