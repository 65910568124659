import React from "react";

const RiskDisclosure = () => {
  return (
    <>
      <section className="about-section">
        <div className="about-title">
          <h4 data-aos="fade-down" data-aos-duration="2000">Risk disclosure</h4>
        </div>

        <div className="container">
          <div className="benefits-gold-investment-services-area">
            <div className="benefits-gold-investment-services">
              <div className="benefits-gold-investment-services-list custom-lists " >
                <ol>
                  <li data-aos="fade-up"
                  data-aos-duration="3000">
                    High risk investment
                    <p>
                      Margin foreign currency trading is high-risk and may not
                      be appropriate for all investors. High leverage can have
                      both beneficial and negative consequences for you.
                      Possible outcomes include losses greater than the
                      investment amount. Before selecting to purchase or sell
                      forex, you should think about your financial objectives,
                      trading experience, and risk tolerance. Investors should
                      be fully informed of the dangers connected with foreign
                      currency transactions. If you have any questions, please
                      speak with an independent financial counselor. Any
                      comments, news, research, analysis, pricing, or other
                      material provided on this website should be viewed as
                      general market information only and not as investment
                      advice. The Company shall not be liable for any loss or
                      damage (including but not limited to any loss of profits)
                      that arise directly
                    </p>
                  </li>
                  <li data-aos="fade-up"
                  data-aos-duration="3000">
                    Margin and Leverage
                    <p>
                      To trade leveraged price difference contracts or FX, you
                      must deposit a set amount of money with the company as
                      margin. Margin often refers to a small fraction of the
                      entire contract price. For example, a contract with a
                      leverage ratio of 100:1 requires 1% of the contract value
                      as a margin. This means that a minor price shift might
                      result in a substantial change in the value of your
                      trading contract, either benefiting you or causing you to
                      lose money. You may lose your first cash injection and
                      need to boost margins to keep your position. If you fail
                      to achieve the margin requirements, your position will be
                      forced closed, and you will be responsible for any losses
                      incurred.
                    </p>
                  </li>
                  <li data-aos="fade-up"
                  data-aos-duration="3000">
                    Network Transaction Risk
                    <p>
                      Trading on the Internet always involves risks such as
                      hardware, software, and network connection problems.
                      Because the Company has no control over the dependability
                      of signals, receiving lines, equipment setup, or Internet
                      connection systems, we cannot be held liable for
                      communication failures, distortions, or delays during
                      Internet transactions. The organization offers backup
                      systems and email trading services, as well as contingency
                      measures to reduce the likelihood of system failure.
                    </p>
                  </li>
                  <li data-aos="fade-up"
                  data-aos-duration="3000">
                    Data Accuracy
                    <p>
                      Everything contained on this website is given exclusively
                      to help traders make informed investing decisions and is
                      subject to change at any time without notice. The Company
                      has taken reasonable steps to ensure the correctness of
                      the information on the website, but cannot guarantee it.
                      We shall not be liable for any loss or damage caused
                      directly or indirectly by any delay or failure to transmit
                      or receive the Website's content, or any instructions or
                      notices that you are unable to log into the Website, or
                      through this Website.
                    </p>
                  </li>
                  <li data-aos="fade-up"
                  data-aos-duration="3000">
                    Issue
                    <p>
                      The contents of this website are not meant to be
                      distributed or provided to anybody who is banned by their
                      country's laws from engaging in such activities for any
                      reason. All services and investments discussed on this
                      website do not apply to residents whose actions are
                      restricted by their country's laws. Visitors to this
                      website are responsible for identifying and following
                      their individual nations' laws and regulations.
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RiskDisclosure;
