import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../Contexts/AuthContext/AuthProvider";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faGear } from "@fortawesome/free-solid-svg-icons";
const LogoutComponent = () => {
  const { LoginWithEmail, setUser } = useContext(AuthContext);
  const from = "/login";
  const navigate = useNavigate();
  const [remove, setremove] = useState(false);
  const SignOut = () => {
    document.cookie = `robicon_token=; expires=${new Date(
      0
    ).toUTCString()}; path=/`;
    localStorage.removeItem("robicon_id");
    const data = null;
    LoginWithEmail(data);
    setUser(null);
    setremove(true);
  };

  useEffect(() => {
    if (remove) {
      navigate(from, { replace: true });
    }
  }, [remove, navigate]);

  return (
    <>
      <button className="user-menu-item d-flex justify-content-between align-items-center w-100" onClick={SignOut}>
        <div className="d-flex justify-content-between align-items-center">
          <FontAwesomeIcon icon={faGear} />
          <p>Logout</p>
        </div>
        <FontAwesomeIcon icon={faChevronRight} />
      </button>

    </>
  );
};

export default LogoutComponent;
