import React from "react";
import "./BuiltOn.css";

const BuiltOn = () => {
  return (
    <>
      <div className="Features___StyledDiv-sc-rdq39d-0 bMknSn">
        <div className="Features___StyledDiv2-sc-rdq39d-1 dzpvBq">
          <div className="Features___StyledDiv3-sc-rdq39d-2 fjkKjW">
            <div className="Features___StyledDiv4-sc-rdq39d-3 ihxxPW" data-aos="zoom-in-up" data-aos-duration="2000">
              <p className="Features___StyledP-sc-rdq39d-4 jLNEyf">
              Built on Digital currency
              </p>
              <p className="Features___StyledP2-sc-rdq39d-5 fvzHHr">
                Say goodbye to inefficient DeFi trading
                <br />
                Join thousands of traders switching to Rubicon for competitive
                liquidity!
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuiltOn;
