import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../../../Contexts/AuthContext/AuthProvider';
import axios from 'axios';
import CustomPagination from '../../../../../CustomPagination/CustomPagination';
import SingleItem from './SingleItem';

const WithdrawalHistory = () => {
    const { authUser } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [paginateData, setPaginateData] = useState([]);
  
    useEffect(() => {
      fetch(
        `${process.env.REACT_APP_API}/api/user/withdrawal/view/${authUser?._id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setData(data.data);
          setPaginateData(data);
          setLoading(false);
        });
    }, []);
  
    const handlePage = (event) => {
      try {
        const pageNumber = event?.selected + 1;
        const config = {
          headers: {
            "content-type": "application/json",
          },
        };
        axios
          .get(
            `${process.env.REACT_APP_API}/api/user/withdrawal/view/${authUser?._id}/?page=${pageNumber}`,
            config
          )
          .then((response) => {
            setData(response?.data?.data);
            setPaginateData(response?.data);
          })
          .catch((error) => {});
      } catch (error) {
        console.log(error);
      }
    };
  
    if(loading){
      return;
    }
    return (
      <>
        {/* History  */}
        <div className="user-data-table">
          <div className="col-xxl-12 col-md-12 pt-5">
            <div className=" text-white card ">
              <div className="card-header d-flex flex-wrap justify-content-between">
                <h4 className="title text-primary">My Withdrawal</h4>
              </div>
              <div className="card-body  text-white p-2">
                <div className="text-white table-responsive ">
                  <table className="text-white table table-responsive-md ">
                    <thead>
                      <tr className=" mx-auto table-header">
                      <th>Network&nbsp;Type</th>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Wallet&nbsp;Address</th>
                  <th>Transaction</th>
                  <th>Progress</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.length !== 0 ? (
                        data.map((data, index) => {
                          if (data) {
                            return (
                              <SingleItem
                                data={data}
                                index={index}
                                key={data?._id}
                                paginateData={paginateData}
                              ></SingleItem>
                            );
                          }
                        })
                      ) : (
                        <tr>
                          <td className="text-muted text-center" colSpan="100%">
                            No records available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {/* paginateLinks */}
                <CustomPagination
                  data={paginateData}
                  handlePage={handlePage}
                ></CustomPagination>
                {/* paginateLinks */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };



export default WithdrawalHistory;