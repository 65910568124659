import React from "react";
import Header from "../Header/Header";
import { Outlet } from "react-router-dom";
import Footer from "../Footer/Footer";
import "./MainDashboard.css";

const MainDashboard = () => {
  return (
    <>
      {/* <div className="main-dashboard"> */}
        <Outlet></Outlet>
      {/* </div> */}
      <Footer></Footer>
    </>
  );
};

export default MainDashboard;
