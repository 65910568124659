import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../../../Contexts/AuthContext/AuthProvider';
import axios from 'axios';
import CustomPagination from '../../../../../CustomPagination/CustomPagination';
import SingleItem from './SingleItem';

const InvestmentPlanHistory = () => {
    const { authUser } = useContext(AuthContext);
  const [HistoryData, setHistoryData] = useState([]);
  const [paginateData, setPaginateData] = useState([]);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API}/api/user/investment/plan/history/view/${authUser?._id}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setHistoryData(data?.data);
        setPaginateData(data);
      });
  }, []);

  const handlePage = (event) => {
    try {
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .get(
          `${process.env.REACT_APP_API}/api/user/investment/plan/history/view/${authUser?._id}?page=${pageNumber}`,
          config
        )
        .then((response) => {
            setHistoryData(response?.data?.data);
          setPaginateData(response?.data);
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
  };


    return (
        <>
            {/* History  */}
            <div className="user-data-table">
                <div className="col-xxl-12 col-md-12 pt-5">
                  <div className=" text-white card ">
                    <div className="card-header d-flex flex-wrap justify-content-between">
                      <h4 className="title text-primary">My Investemt Plan</h4>
                    </div>
                    <div className="card-body  text-white p-2">
                      <div className="text-white table-responsive ">
                        <table className="text-white table table-responsive-md ">
                          <thead>
                            <tr className=" mx-auto table-header">
                              <th>Invest&nbsp;Date</th>
                              <th>Invest&nbsp;Amount</th>
                              <th>Total&nbsp;Return</th>
                              <th>C.Profit</th>
                              <th>Remaining</th>
                              <th>End&nbsp;At</th>
                            </tr>
                          </thead>
                          <tbody>
                            {HistoryData?.length !== 0 ? (
                              HistoryData.map((data, index) => {
                                if (data) {
                                  return (
                                    <SingleItem
                                      data={data}
                                      index={index}
                                      key={data?._id}
                                      paginateData={paginateData}
                                    ></SingleItem>
                                  );
                                }
                              })
                            ) : (
                              <tr>
                                <td
                                  className="text-muted text-center"
                                  colSpan="100%"
                                >
                                  No records available
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      {/* paginateLinks */}
                      <CustomPagination
                        data={paginateData}
                        handlePage={handlePage}
                      ></CustomPagination>
                      {/* paginateLinks */}
                    </div>
                  </div>
                </div>
              </div>  
        </>
    );
};

export default InvestmentPlanHistory;