import React from "react";
import "./Customers.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faArrowLeft,
  faBars,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";
const Customers = () => {
  return (
    <>
      <section className="customers-section">
        <section className="resources-section">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <div
                  className="resources-content-area"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <div>
                    <h4>Why your customers will love it</h4>
                    <p>
                      Rubicon Liberty Group Limited allows customers to connect
                      their wallets and pay for products of their choice. But we
                      support digital currency for transactions, as simple as
                      that.
                    </p>
                    <div className="resources-content-dis-area">
                      <div className="resources-content-dis">
                        <FontAwesomeIcon icon={faCircleCheck} />
                        <span>
                          <b> Hundreds of currencies, multiple wallets. </b>{" "}
                          Customers base, Bitcoin, Ethereum, USDT, Salon,
                          Dog-coin. And can pay with their preferred wallet and
                          currency across the polygon.
                        </span>
                      </div>
                      <div className="resources-content-dis">
                        <FontAwesomeIcon icon={faCircleCheck} />
                        <span>
                          <b>Pre-configured payments. </b>
                          Merchants share payment information directly with
                          their wallets, eliminating the need for manual data
                          entry.
                        </span>
                      </div>
                      <div className="resources-content-dis">
                        <FontAwesomeIcon icon={faCircleCheck} />
                        <span>
                          {" "}
                          <b>Instant payment confirmation. </b> Real-time
                          transaction validation for immediate customer
                          reassurance.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="customers-mobile-area">
                  <div className="customers-mobile">
                    <div className="customers-mobile-header">
                      <FontAwesomeIcon icon={faArrowLeft} />
                      <p>payment complate</p>
                      <FontAwesomeIcon icon={faBars} />
                    </div>
                    <div className="customers-mobile-body">
                      <div className="customers-mobile-payment">
                        <FontAwesomeIcon icon={faCreditCard} />
                        <h5>
                          Paid 99.7 <span>USD</span>
                        </h5>
                        <p>The Hundreds</p>
                      </div>
                      <div className="customers-mobile-payment-details-area">
                        <div className="customers-mobile-payment-details d-flex align-items-center justify-content-between">
                          <p>Form  </p>
                          <span>USDT balance of rovicon</span>
                        </div>
                        <div className="customers-mobile-payment-details d-flex align-items-center justify-content-between">
                          <p>Order Code  </p>
                          <span>I87875GDGLK9</span>
                        </div>
                        <div className="customers-mobile-payment-details d-flex align-items-center justify-content-between">
                          <p>Sub Total  </p>
                          <span>$99 + 99 USDT</span>
                        </div>
                        <div className="customers-mobile-payment-details d-flex align-items-center justify-content-between">
                          <p>Network Fee  </p>
                          <span>$0.02 + 0,02 USDT</span>
                        </div>
                        <div className="customers-mobile-payment-details d-flex align-items-center justify-content-between">
                          <p>Date  </p>
                          <span>02-08-2020</span>
                        </div>
                        <div className="customers-mobile-payment-details d-flex align-items-center justify-content-between">
                          <p>Status  </p>
                          <span>Complate</span>
                        </div>
                      </div>

                      <div className="customers-mobile-blocking">
                        <h4>View on block explore</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <div className="more-control-sectiona">
        <div
          className="section"
          id="transf"
          data-aos="zoom-in-up"
          data-aos-duration="2000"
        ></div>
      </div>
    </>
  );
};

export default Customers;
