import React, { createContext, useEffect, useState } from 'react';
const getlocalStorageItem = () =>{
    let userId = localStorage.getItem("robicon_id");
    if(userId){

        return JSON.parse(userId);
    }else{
// console.log("is ok")

        return null;
    }

}
 
export const AuthContext = createContext('');

const AuthProvider = ({children}) => {
    const [authId, setAuthId] = useState(getlocalStorageItem());
    const [authUser, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const LoginWithEmail = (data) =>{
        setAuthId(data);
        // console.log("context api : ", data);
    }
  
      
    function getCookie(name) {
        const cookieValue = document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`);
        return cookieValue ? cookieValue.pop() : '';
      }

      let token = getCookie('robicon_token');

      useEffect(() => {
        if(authId){
            fetch(`${process.env.REACT_APP_API}/api/user/auth/view/single/${authId?.user_id}`, {
                method: 'GET',
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data?.success === false) {
                        const SignOut = () => {
                          document.cookie = `robicon_token=; expires=${new Date(
                            0
                          ).toUTCString()}; path=/`;
                          localStorage.removeItem("robicon_id");
                          const data = null;
                          LoginWithEmail(data);
                        };
                        SignOut();
                        return;
                      }else{
                        setUser(data.data);
                        setLoading(false);
                      }
                })
        }else{
            setLoading(false)
        }
        
    }, [authId])
    const authInfo = {authUser, LoginWithEmail,  setUser, token, authId, setAuthId  };
    if(loading){
        return;
    }
    return (
        <AuthContext.Provider value={authInfo}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;