import React from "react";
import "./FinancialExchange.css";
import { Typewriter } from "react-simple-typewriter";
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Autoplay} from "swiper/modules";

const FinancialExchange = () => {
  return (
    <>
      <section>
        <div className="BlurBackground___StyledDiv-sc-8xdmpl-0 stQFp"></div>
        <div>
          <div className="Header___StyledDiv2-sc-yzxm8k-7 kxDAN" data-aos="fade-down"  data-aos-duration="2000">
            <div className="Header___StyledDiv3-sc-yzxm8k-8 gfrrKP">
              <div className="Header___StyledDiv4-sc-yzxm8k-9 hdhemy">
                <p className="Header___StyledP-sc-yzxm8k-10 dBBftA">
                  LIVE ON&nbsp;
                  <span>
                    <Typewriter
                      loop
                      cursor
                      cursorStyle=""
                      typeSpeed={200}
                      deleteSpeed={200}
                      delaySpeed={1000}
                      words={["ETHEREUM", "OPTIMISM", "ARBITRUM", "BASE"]}
                    />
                  </span>
                </p>
              </div>
              <div className="Header___StyledDiv5-sc-yzxm8k-11 hCVRhG">
                <h2 className="Header___StyledH-sc-yzxm8k-12 kiLjyu">
                  The World’s Last Financial Exchange Trading Platfrom
                </h2>
                <div className="PromoTransition___StyledDiv-sc-ys2ght-1 bwAAvw">
                  <div
                    className="text-transition PromoTransition___StyledTextTransition-sc-ys2ght-0 hMQuxx"
                    style={{
                      whiteSpace: "normal",
                      display: "flex",
                      height: "24px",
                    }}
                  >
                    <Swiper
                      slidesPerView={1}
                      autoplay={{
                        delay: 4500,
                        disableOnInteraction: false,
                      }}
                      loop={true}
                      modules={[Autoplay]}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                        <div
                          style={{ opacity: "1", transform: "translateY(0%)" }}
                      className="financial-exchange-silder"  >
                          Built on an open, non-custodial trading platform for Digital currency
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div
                          style={{ opacity: "1", transform: "translateY(0%)" }}  className="financial-exchange-silder"
                        >
                         Gasless MEV-resistant trading with competitive execution
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div
                          style={{ opacity: "1", transform: "translateY(0%)" }}  className="financial-exchange-silder"
                        >
                        Earn as a high-frequency DEX market maker and trader
                        </div>
                      </SwiperSlide>
                    </Swiper>
                    {/* <div style={{ opacity: "1", transform: "translateY(0%)" }}>
                      Gasless, MEV-resistant trading with competitive execution
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="Header___StyledDiv6-sc-yzxm8k-13 bXIhIs">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href=""
                  className="Header___StyledA-sc-yzxm8k-14 gvOYXD group"
                >
                  Download App
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    className="Header___StyledLightningBoltIcon-sc-yzxm8k-15 eDRmim"
                  >
                    <path
                      fillRule="evenodd"
                      d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FinancialExchange;
