import React, { useContext, useEffect, useState } from "react";
import "./InvestmentPlan.css";
import SingleItem from "./SingleItem";
import InvestModal from "./InvestModal/InvestModal";
import axios from "axios";
import { AuthContext } from "../../../../../Contexts/AuthContext/AuthProvider";
import { Link } from "react-router-dom";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InvestmentPlanHistory from "./InvestmentPlanHistory/InvestmentPlanHistory";

const InvestmentPlan = () => {
  const { authUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/api/user/investment/plan/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data?.data);
        setLoading(false);
      });
  }, []);

  const [handleModalShow, setHandleModalShow] = useState(false);
  const [Modalshow, setModalshow] = useState(true);
  const [SingleData, setSingleData] = useState([]);

  const handleModal = (id) => {
    if (id) {
      fetch(
        `${process.env.REACT_APP_API}/api/user/investment/plan/view/${id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setSingleData(data?.data);
          setModalshow(true);
        });
      setHandleModalShow(true);
    }
  };

 
  return (
    <>
      {handleModalShow && (
        <>
          <InvestModal
            className="posce-trade-modal"
            show={Modalshow}
            singledata={SingleData}
            onHide={() => setModalshow(false)}
          />
        </>
      )}

      <section className="investment-plan-section">
        <div className="planwrap">
          <div className="container">
          <div className="user-form-back">
            <Link to='/user/menu'> <FontAwesomeIcon icon={faChevronLeft} className="text-start" /></Link>
            <h6>OUR INVESTMENT PLANS</h6>
          </div>
            <div className="investment-plan-title">
            </div>
            <div className="demo">
              <div className="row gy-3">
                {data?.length !== 0 ? (
                  data.map((data, index) => {
                    if (data) {
                      return (
                        <SingleItem
                          data={data}
                          index={index}
                          key={data._id}
                          handleModal={handleModal}
                        ></SingleItem>
                      );
                    }
                  })
                ) : (
                  <>
                    <div className="text-center h-50">
                      <h6> Data not found</h6>
                    </div>
                  </>
                )}
              </div>
           
            </div>

            <InvestmentPlanHistory />
          </div>
        </div>
      </section>
    </>
  );
};

export default InvestmentPlan;
