import React from "react";
import "./MoreControl.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faShieldHeart,
  faShieldHalved,
  faTicket,
} from "@fortawesome/free-solid-svg-icons";
const MoreControl = () => {
  return (
    <>
      <section className="more-control-section">
        <div className="more-control">
          <h2
            className="jFkHiP more-control-title"
            data-aos="fade-down"
            data-aos-duration="2000"
          >
            More Control When You're Ready
          </h2>
        </div>
        <div className="more-control-mobile-container container">
          <div className="more-control-card-area">
            <div className="row row-gap-3">
              <div className="col-6 ">
                <div
                  className="more-control-card"
                  data-aos="zoom-in-up"
                  data-aos-duration="2000"
                >
                  <FontAwesomeIcon icon={faShieldHeart} />
                  <h5>Friendly and trustworthy </h5>
                  <p>
                    Rubicon Liberty Group Limited makes learning and using each
                    of the company's products fun and easy. No "technical talk"
                    or confusing steps. Manage your wealth easily - and have fun
                    doing it!
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div
                  className="more-control-card"
                  data-aos="zoom-in-up"
                  data-aos-duration="2000"
                >
                  <FontAwesomeIcon icon={faLock} />
                  <h5>Keep your data private </h5>
                  <p>
                    The Rubicon Liberty Group Limited Mobile platform encrypts
                    private keys and transaction data on your mobile device for
                    your eyes only. All your data remains in private protection.
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div
                  className="more-control-card"
                  data-aos="zoom-in-up"
                  data-aos-duration="2000"
                >
                  <FontAwesomeIcon icon={faShieldHalved} />
                  <h5>Enhanced security</h5>
                  <p>
                    Your assets are protected by our physical and cyber security
                    teams.
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div
                  className="more-control-card"
                  data-aos="zoom-in-up"
                  data-aos-duration="2000"
                >
                  <FontAwesomeIcon icon={faTicket} />
                  <h5>24/7 human support </h5>
                  <p>
                    We won't leave you alone. Rubicon Liberty Group Limited
                    provides an online help desk and 24/7 customer support to
                    get quick answers to your queries.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
     
    </>
  );
};

export default MoreControl;
