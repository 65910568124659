import React from "react";
import { faHandHoldingDollar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const SingleItem = ({ data, handleSubmitData, refSubmitDis, errorMessage }) => {
  if (data?.template === 1) { 
    return (
      <> 
        <div className="col-10 m-auto mb-3 loan-card">
          <div className="card text-center">
            <div className="title">
              <div className="loan-plane-icon">
               <FontAwesomeIcon icon={faHandHoldingDollar} />
              </div>
              <h2>{data?.name}</h2>
            </div>
            <div className="price">
              <h4>
                {data?.percentage}%
                <span className="text-small">
                  &nbsp;/ {data?.period} {data?.period === 1 ? "Day" : "Days"}
                </span>
              </h4>
            </div>
            <div className="option">
              <ul>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Take
                  Minimum: ${data?.minimum_amount}
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Take
                  Maximum: ${data?.maximum_amount}
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Per
                  Installment: {data?.percentage}%
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>
                  Installment Interval: {data?.period}{" "}
                  {data?.period === 1 ? "Day" : "Days"}
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Total
                  Installment: 1
                </li>
              </ul>
            </div>
            <form onSubmit={handleSubmitData}>
              <input type="hidden" name="id" value={data?._id} />
              <div className="modal-body loan-form-section">
                <div className="form-group">
                  <label htmlFor="amount" className="required">
                    Amount
                  </label>
                  <div className="input-group  custom-input-group">
                    <input
                      type="text"
                      step="any"
                      name="amount"
                      className="form-control form--control"
                      placeholder="Enter An Amount"
                      id="amount"
                    />
                    <span className="input-group-text "> USD </span>
                  </div>
                  {errorMessage?.id && errorMessage?.id === data?._id && (
                    <>
                      {errorMessage?.errors?.amount && (
                        <span className="text-danger pt-2">
                          {errorMessage?.errors?.amount?.msg}
                        </span>
                      )}

                      {errorMessage?.message && (
                        <span className="text-danger pt-2">
                          {errorMessage?.message}
                        </span>
                      )}
                    </>
                  )}
                </div>
                <button
                  type="submit"
                  className=" text-white mt-2 submit-btn  w-100"
                  ref={refSubmitDis}
                >
                  Apply Loan
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }

  if (data?.template === 2) {
    return (
      <>
        <div className="col-10 m-auto mb-3 loan-card">
          <div className="card text-center">
            <div className="title">
              <div className="loan-plane-icon">
               <FontAwesomeIcon icon={faHandHoldingDollar} />
              </div>
              <h2>{data?.name}</h2>
            </div>
            <div className="price">
              <h4>
                {data?.percentage}%
                <span className="text-small">
                  &nbsp;/ {data?.period} {data?.period === 1 ? "Day" : "Days"}
                </span>
              </h4>
            </div>
            <div className="option">
              <ul>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Take
                  Minimum: ${data?.minimum_amount}
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Take
                  Maximum: ${data?.maximum_amount}
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Per
                  Installment: {data?.percentage}%
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>
                  Installment Interval: {data?.period}{" "}
                  {data?.period === 1 ? "Day" : "Days"}
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Total
                  Installment: 1
                </li>
              </ul>
            </div>
            <form onSubmit={handleSubmitData}>
              <input type="hidden" name="id" value={data?._id} />
              <div className="modal-body loan-form-section">
                <div className="form-group">
                  <label htmlFor="amount" className="required">
                    Amount
                  </label>
                  <div className="input-group  custom-input-group">
                    <input
                      type="text"
                      step="any"
                      name="amount"
                      className="form-control form--control"
                      placeholder="Enter An Amount"
                      id="amount"
                    />
                    <span className="input-group-text "> USD </span>
                  </div>
                  {errorMessage?.id && errorMessage?.id === data?._id && (
                    <>
                      {errorMessage?.errors?.amount && (
                        <span className="text-danger pt-2">
                          {errorMessage?.errors?.amount?.msg}
                        </span>
                      )}

                      {errorMessage?.message && (
                        <span className="text-danger pt-2">
                          {errorMessage?.message}
                        </span>
                      )}
                    </>
                  )}
                </div>
                <button
                  type="submit"
                  className=" text-white mt-2 submit-btn  w-100"
                  ref={refSubmitDis}
                >
                  Apply Loan
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }

  if (data?.template === 3) {
    return (
      <>
        <div className="col-10 m-auto mb-3 loan-card">
          <div className="card text-center">
            <div className="title">
              <div className="loan-plane-icon">
               <FontAwesomeIcon icon={faHandHoldingDollar} />
              </div>
              <h2>{data?.name}</h2>
            </div>
            <div className="price">
              <h4>
                {data?.percentage}%
                <span className="text-small">
                  &nbsp;/ {data?.period} {data?.period === 1 ? "Day" : "Days"}
                </span>
              </h4>
            </div>
            <div className="option">
              <ul>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Take
                  Minimum: ${data?.minimum_amount}
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Take
                  Maximum: ${data?.maximum_amount}
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Per
                  Installment:
                  {data?.percentage}%
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>
                  Installment Interval: {data?.period}{" "}
                  {data?.period === 1 ? "Day" : "Days"}
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i>Total
                  Installment: 1
                </li>
              </ul>
            </div>
            <form onSubmit={handleSubmitData}>
              <input type="hidden" name="id" value={data?._id} />
              <div className="modal-body loan-form-section">
                <div className="form-group">
                  <label htmlFor="amount" className="required">
                    Amount
                  </label>
                  <div className="input-group  custom-input-group">
                    <input
                      type="text"
                      step="any"
                      name="amount"
                      className="form-control form--control"
                      placeholder="Enter An Amount"
                      id="amount"
                    />
                    <span className="input-group-text "> USD </span>
                  </div>
                  {errorMessage?.id && errorMessage?.id === data?._id && (
                    <>
                      {errorMessage?.errors?.amount && (
                        <span className="text-danger pt-2">
                          {errorMessage?.errors?.amount?.msg}
                        </span>
                      )}

                      {errorMessage?.message && (
                        <span className="text-danger pt-2">
                          {errorMessage?.message}
                        </span>
                      )}
                    </>
                  )}
                </div>
                <button
                  type="submit"
                  className=" text-white mt-2 submit-btn  w-100"
                  ref={refSubmitDis}
                >
                  Apply Loan
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
};

export default SingleItem;
