import React from "react";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const SingleItem = ({ data, handleModal }) => {
  return (
    <>
      <div className="col-12">
        <div
          className="pricingTable blue"
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          <div className="pricingTable-header">
            <h3 className="title">{data?.promotion_name} </h3>
          </div>
          <div className="price-value">
            <span>
              {data?.percentage}%
              {data?.instant === 1 ? "DAILY" : data?.period_type}
            </span>
          </div>
          <ul className="pricing-content">
            <li>
              <FontAwesomeIcon icon={faCircleCheck} />{data?.period} DAYS
            </li>
            <li>
              <FontAwesomeIcon icon={faCircleCheck} />${data?.min_amount} - $
              {data?.max_amount}
            </li>
            <li>
              <FontAwesomeIcon icon={faCircleCheck} />
              Principal
              {data?.principal === 1 ? (
                <span> Returns</span>
              ) : data?.principal === 2 ? (
                <span> Included</span>
              ) : (
                ""
              )}
            </li>
          </ul>

          <div className="pricingTable-signup">
            <button onClick={() => handleModal(data?._id)}>INVEST</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleItem;
