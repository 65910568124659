import React, { useContext, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../../../../../Contexts/AuthContext/AuthProvider";
const InvestModal = (props) => {
  const { singledata, onHide } = props;
  const { authUser } = useContext(AuthContext);

  const refSubmitDis = useRef();
  const [errorMessage, setErrorMessage] = useState([]);

  const handleSubmitData = async (event) => {
    event.preventDefault();
    refSubmitDis.current.setAttribute("disabled", true);
    setErrorMessage();
    if (singledata?._id) {
      const formData = new FormData(event.currentTarget);
      const InpuData = Object.fromEntries(formData);
      const storeData = {
        id: singledata?._id,
        user_id: authUser?._id,
        ...InpuData,
      };
      try {
        const config = {
          headers: {
            "content-type": "application/json",
            authorization: `Beare ${authUser.token}`,
          },
        };

        await axios
          .post(
            `${process.env.REACT_APP_API}/api/user/investment/plan/store`,
            storeData,
            config
          )
          .then((data) => {
            toast.success(`${data.data.message}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            refSubmitDis.current.removeAttribute("disabled");
            if (data?.data?.success) {
              onHide();
            }
            event.target.reset();
          })
          .catch((error) => {
            if (error?.response?.data?.errors) {
              setErrorMessage(error?.response?.data?.errors);
            } else {
              setErrorMessage(error?.response?.data);
            }
            refSubmitDis.current.removeAttribute("disabled");
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        className=" user-investment-modal"
      >
        <Modal.Body>
          <div className="user-investment-modal-header pb-5">
            <h5>Investment Plan</h5>
            <FontAwesomeIcon icon={faXmark} onClick={props.onHide} />
          </div>
          <p>
            <strong>Name: </strong>
            {singledata?.promotion_name}
          </p>
          <p>
            <strong>Percentage:</strong>
            <span style={{ color: "#fff" }}>{singledata?.percentage}%</span>
          </p>
          <p>
            <strong>Minimum:</strong>
            <span style={{ color: "#fff" }}>{singledata?.min_amount}$</span>
          </p>
          <p>
            <strong>Maximum:</strong>
            <span style={{ color: "#fff" }}>{singledata?.max_amount}$</span>
          </p>

          <br />
          <p className="mb-1">
            <strong>Amount</strong>
          </p>
          <form onSubmit={handleSubmitData}>
            <div className="bargaining__fieldset">
              <div id="timeSelection" className="time-selection mt-2">
                <div className="period-buttons"></div>
              </div>
              <input
                className="field__input bargaining__field"
                type="text"
                name="amount"
                placeholder="Amount($)"
                id="amount"
              />
              {errorMessage?.amount && (
                <span className="text-danger pt-2">
                  {errorMessage?.amount?.msg}
                </span>
              )}
              {errorMessage?.message && (
                <span className="text-danger pt-2">
                  {errorMessage?.message}
                </span>
              )}
            </div>

            <p className="pb-2 pt-3">
              <strong>Available Balance: </strong> {authUser?.balance}$
            </p>

            <div className="text-center">
              <button className="user-investment-modal-btn" ref={refSubmitDis}>
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InvestModal;
