import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dateFormat from "dateformat";
import HTMLReactParser from "html-react-parser";
import { LazyLoadImage } from "react-lazy-load-image-component";
const NewsDetails = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/api/user/news/view/${id}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data.data);
        setLoading(false);
      });
  }, []);
  if (loading) {
    return;
  }
  return (
    <>
      <section className="news-details-section">
        <div className="container">
          <div className="news-details-title">
            <h5>{data?.title}</h5>
          </div>
          <div className="news-details-image">
            <LazyLoadImage
              alt="img"
              effect="blur"
              src={`${process.env.REACT_APP_API}/${data?.image}`}
            />
            <span>
              Posted On {dateFormat(data?.createdAt, "mmmm dd, yyyy")}
            </span>
          </div>
          <div className="news-details-dis">
            <h5>{data?.dis}</h5>
          </div>
          <div className="news-details">
            {data?.news ? HTMLReactParser(data?.news) : ""}
          </div>
        </div>
      </section>
    </>
  );
};

export default NewsDetails;
