import React, { useContext, useRef, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { AuthContext } from "../../../../../Contexts/AuthContext/AuthProvider";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import WithdrawalHistory from "./WithdrawalHistory/WithdrawalHistory";
const Withdrawal = () => {
  const { authUser } = useContext(AuthContext);

  const [errorMessage, setErrorMessage] = useState([]);
  const refSubmitDis = useRef();

  const handleSubmitData = (event) => {
    event.preventDefault();
    setErrorMessage();
    refSubmitDis.current.setAttribute("disabled", true);
    const formData = new FormData(event.currentTarget);
    const InpuData = Object.fromEntries(formData);
    console.log(InpuData);
    const storeData = { user_id: authUser?._id, ...InpuData };
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API}/api/user/withdrawal/confirm`,
        storeData,
        config
      )
      .then((data) => {
        event.target.reset();
        toast.success(`${data.data.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        refSubmitDis.current.removeAttribute("disabled");
      })
      .catch((error) => {
        if (error?.response?.data?.success === false) {
          if (error?.response?.data?.errors) {
            setErrorMessage(error?.response?.data?.errors);
          } else {
            setErrorMessage(error?.response?.data);
          }
          refSubmitDis.current.removeAttribute("disabled");
        }
      });
  };

  return (
    <>
      <div className="user-form-section">
        <div className="container">
          <div className="user-form-back">
            <Link to="/user/menu">
              <FontAwesomeIcon icon={faChevronLeft} className="text-start" />
            </Link>
            <h6>Withdrawal</h6>
          </div>
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmitData}>
                <div className="row gutters">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                    <div className="form-group">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                        Network
                      </label>
                      <select
                        name="NetworkType"
                        className="form-select"
                        required
                      >
                        <option selected disabled>
                          select
                        </option>
                        <option value="BTC">BTC</option>
                        <option value="ETH">ETH</option>
                        <option value="USDT">USDT</option>
                      </select>
                      {errorMessage?.NetworkType && (
                        <span className="text-danger ">
                          {errorMessage?.NetworkType?.msg}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                    <div className="form-group">
                      <label
                        htmlFor="exampleFormControlInput2"
                        className="form-label"
                      >
                        Amount
                      </label>
                      <input
                        type="text"
                        name="Amount"
                        className="form-control"
                        id="exampleFormControlInput2"
                        aria-describedby="emailHelp"
                      />
                      {errorMessage?.Amount && (
                        <span className="text-danger ">
                          {errorMessage?.Amount?.msg}
                        </span>
                      )}
                      {errorMessage?.message && (
                        <span className="text-danger ">
                          {errorMessage?.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                    <div className="form-group">
                      <label
                        htmlFor="exampleFormControlInput2"
                        className="form-label"
                      >
                        Withdrawal Address
                      </label>
                      <input
                        type="text"
                        name="WithdrawalAddress"
                        className="form-control"
                        id="exampleFormControlInput2"
                        aria-describedby="emailHelp"
                      />
                      {errorMessage?.WithdrawalAddress && (
                        <span className="text-danger ">
                          {errorMessage?.WithdrawalAddress?.msg}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="user-submit-btn">
                  <button
                    type="submit"
                    id="submit"
                    className="btn btn-primary px-5 fw-normal d-block mt-1"
                    ref={refSubmitDis}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>

          <WithdrawalHistory />
        </div>
      </div>
    </>
  );
};

export default Withdrawal;
